import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box, Text } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Usage`}</h2>
    <p>{`The box component is considered a utility component, as it can be used for something as simple as a rounded corner box.`}</p>
    <p>{`By default there are no additional styles, as these can be achieved using styled system props to enable custom theme-aware styling.`}</p>
    <h2>{`Accessibility and usability expectations`}</h2>
    <p>{`The component provides no semantic information. It's only function is to provide a container for other content.`}</p>
    <h3>{`Implementation requirements`}</h3>
    <p>{`None. The component is a simple div. No semantic information is provided by the component and therefore additional markup will be needed to provide semantic information if required.`}</p>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Box" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      